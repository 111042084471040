import { useContext, useEffect, useState } from "react"
import {Route, RouteProps, Switch, BrowserRouter as Router} from "react-router-dom"

import Overview from '../pages/Overview'
import EditionDetail from '../pages/EditionDetail'
import Reader from '../pages/Reader'
import Category from '../pages/Category'
import Search from '../pages/Search'
import Favorites from '../pages/Favorites'
import KioskOverview from '../pages/KioskOverview'
import NotFound from '../pages/NotFound'
import { ThemeContext } from 'styled-components';
import CookieBot from 'react-cookiebot'
import ReactCookieFirst from "react-cookiefirst";
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

import {MainLayout, ReaderLayout, OverviewLayout, CourseLayout, KioskOverviewLayout} from "./layouts"
import { useTracking } from "../hooks/useTracking"

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }: RouteProps) => (
    <Route {...rest} render={props => (
      <Layout match={props.match}>
        <Component {...props} />
      </Layout>
    )} />
  )

  LayoutRoute.defaultProps = {
      layout: MainLayout
  }

const AppRouter = () => {
    const themeContext = useContext(ThemeContext);
    const [cookieConsent, setCookieConsent] = useState(false)
    const {initializeTracking} = useTracking()
    
    useEffect(() => {
      setCookieConsent(getCookieConsentValue() === 'true')
      if(themeContext.tracking.cookieConsent){
        if(themeContext.tracking.cookieConsent.type !== 'Free' || cookieConsent){
          initializeTracking()
        }
      }
      
    }, [cookieConsent])

    const renderCookieConsent = () => {
      if(themeContext.tracking.cookieConsent){

      
        switch (themeContext.tracking.cookieConsent.type) {
          case 'CookieBot':
              return <CookieBot domainGroupId={themeContext.tracking.cookieConsent.id}/>
          case 'CookieFirst':
              return <ReactCookieFirst apiKey={themeContext.tracking.cookieConsent.id} />
          case 'Free':
            return (
              //@ts-ignore
              <CookieConsent
                style={{background: "black",  flexDirection: "column", padding: "3rem", width: "auto"}}
                contentStyle={{flex: "1", margin: 0, marginBottom: "20px"}}
                buttonStyle={{background: "#FFFF00", fontWeight: "bold", fontSize: "14px", padding: "0.5rem 1rem", borderRadius: "2px", color: "black", margin: "0 2px"}}
                declineButtonStyle={{background: "#FFFF00", fontWeight: "bold", fontSize: "14px", padding: "0.5rem 1rem", borderRadius: "2px", color: "black", margin: "0 2px"}}
                flipButtons
                enableDeclineButton
                buttonText="Ich akzeptiere"
                declineButtonText="Ich lehne ab"
                onAccept={() => {
                  setCookieConsent(true)
                }}
                onDecline={() => {
                  console.log("Cookies declined")
                }}
              ><span style={{fontWeight: "bold",fontSize: "24px"}}>
                Ihre Privatsphäre ist uns wichtig
                </span>
                <br/>
                <br/>
                <span style={{  fontSize: "16px"}}>
                Diese Website verwendet Cookies und Targeting Technologien, um Ihnen ein besseres Internet-Erlebnis zu ermöglichen und die Werbung, die Sie sehen, besser an Ihre Bedürfnisse anzupassen. Diese Technologien nutzen wir außerdem, um Ergebnisse zu messen, um zu verstehen, woher unsere Besucher kommen oder um unsere Website weiter zu entwickeln.
                  </span></CookieConsent>
            )

          default:
            <></>

        }
      } else {
        return <></>
      }
    }

    return(
        <Router basename={themeContext.directory}>           
            {renderCookieConsent()}
            <Switch>
                <LayoutRoute exact path="/" layout={themeContext.newsSlider.image ? OverviewLayout : MainLayout} component={Overview}/>
                <LayoutRoute exact path="/search" component={Search}/>
                <LayoutRoute exact path="/favorites"  component={Favorites}/>
                {
                  themeContext.sections.length > 2 && themeContext.sections.map((section) => (
                    <LayoutRoute exact path={"/category/" + section.id} component={Category}/>
                  ))
                }
                <LayoutRoute exact path="/detail/:id" component={EditionDetail}/>
                <LayoutRoute exact path="/edition/:id" component={EditionDetail}/> {/* FYI: Changed the default route for this to /detail, this line is for backwards compatibility with existing customer links and bookmarks */}
                <LayoutRoute exact path="/kiosks" layout={KioskOverviewLayout} component={KioskOverview}/>
                <LayoutRoute exact path="/edition/:id" component={EditionDetail}/>
                <LayoutRoute path="/reader/:id" layout={ReaderLayout}component={Reader}/>
                <LayoutRoute path="/course/:id" layout={CourseLayout}component={Reader}/>
                <LayoutRoute path="/category/:id/all" component={Category}/>
                <LayoutRoute path="/category/:id" component={Category}/>
                <LayoutRoute component={NotFound}/>
                
            </Switch>

        </Router>
    )
}

export default AppRouter