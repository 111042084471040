import { useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import SVG from "react-inlinesvg";
import IconChevronRight from '../img/icons/icon_chevron_right.svg'
import { useTranslation } from 'react-i18next';

import Card from './Card'
import {device} from "../media"

type Props = {
  route?: any,
  category: any,
  name: string,
  updateSection?: any
}

const EditionListShort = ({route, category, name, updateSection}: Props) => {
  const {t} = useTranslation()

  return (
      <Wrapper>
        <Head>
          <Headline>
            {name}
          </Headline>
          <StyledLink to={category.id.toString() === route || category.id === 0 ? `/category/${category.id}/all` : `/category/${category.id}`}>
            {t('show_all')}
            
            <Icon src={IconChevronRight}/>
          </StyledLink>
        </Head>
        <Content>
        {
            category.products && category.products.map((item, index) => (
              index <= 2 &&
              <Card key={index} edition={item}/>
            ))
          }
        {
            category.editions && category.editions.map((item, index) => (
              index <= 2 &&
              <Card key={index} edition={item}/>
            ))
          }
        </Content>
      
      </Wrapper>
  )
}

export default EditionListShort


const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 44px;
    
`

const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media ${device.mobile}{
        margin: 0 -4px;
    }

`

const Content = styled.div`
  display: flex;
  margin: 0 -12px;
  margin-top: 4px;
  
  @media ${device.mobile}{
        overflow-x: scroll;
        margin-top: 8px;
        width: 100%;
    }
`

const Headline = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 125%;
  color: ${props => props.theme.color.grey_900};
  margin-right: 16px;
  @media ${device.mobile}{
    max-width: 50%;
    }

`

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.grey_600};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  & path {
    fill: ${props => props.theme.color.grey_600};
  }
  &:hover {
    color: ${props => props.theme.color.grey_900};
    & path {
      fill: ${props => props.theme.color.grey_900};

    }
  }
`

const Icon = styled(SVG)`
  margin-left: 8px;
`
