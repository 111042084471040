import logo from './logo.svg'
import editionIcon from '../../../img/icons/icon_book.svg'
import courseIcon from '../../../img/icons/icon_academy.svg'
import banner from './banner.jpg'

export const theme = {
    color: {
      primary: "#44C799",
      primaryHover: "#00956b",
    },
    logoImage: logo,
    backend: "https://kitalino.publishing.one/",
    header: {
      customer: "KITALINO",
      title: "Fachimpulse",
      image: banner,
      fontColor: "#0E0E2B",
    },
    sections: [
      {
        id: 1,
        label: "Publikationen",
        label_single: "Publikation",
        type: "EDITION",
        icon: editionIcon,
        root: "edi"
      },
      {
        id: 2,
        label: "Akademie",
        label_single: "Akademie",
        type: "COURSE",
        icon: courseIcon,
        root: "ip"
      },
    ],
    tracking: {
      type: 'Matomo',
      urlBase: 'https://kitalino.matomo.cloud',
      siteId: 3,
    },
    info: [
      {
        text: "Kitalino.com",
        link: 'https://kitalino.com/'
      },
      {
      text: 'Impressum',
      link: 'https://kitalino.com/impressum/'
    },
      {
      text: 'Datenschutz',
      link: 'https://kitalino.com/datenschutz/'
    },],
    login: {
      type: "internal",
      subscriptionTitle: "Registrierung",
      subscriptionText: "Teste sämtliche KITALINO Funktionen inklusive dem \"Fachimpulse GRATIS-PAKET\" für 4 Wochen kostenlos und unverbindlich.",
      links: [{
        id: 1,
        text: 'Hier registrieren',
        url: "https://einrichtungen.kitalino.com/#/registrieren"
      }
      ]
    }
  };