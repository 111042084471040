import {useState, useEffect, useContext} from 'react'
import {connect} from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import moment from 'moment-with-locales-es6'
import convert from 'xml-js'

import {loadEditionsFromXML} from '../redux/actions/editionActions'
import Button from '../components/Button'
import ImagePreview from '../components/ImagePreview'
import Breadcrumbs from '../components/Breadcrumbs'
import ContentBody from '../components/ContentBody'
import IconFavorites from '../img/icons/icon_favorites.svg'
import MetaDataItem from '../components/MetaDataItem';
import ExpandableText from '../components/ExpandableText';
import {addFavorite, deleteFavorite} from '../redux/actions/favoritesActions'
import EmptyContent from '../components/EmptyContent';
import {device} from "../media"
import { ThemeContext } from 'styled-components';
import LoginModal from '../components/LoginModal';
import InfoModal from '../components/InfoModal';
import {login} from "../redux/actions/userActions"
import {getEditionSubModel} from '../helper/editionHelper'
import {useTracking} from '../hooks/useTracking'


const EditionDetail = ({ match, categories, addFavorite, deleteFavorite, isFavorite, user, subscriptions, login}) => {
    const history = useHistory()
    const location = useLocation()
    const {t} = useTranslation()
    const [edition, setEdition]: any = useState()
    const [loginModalActive, setLoginModalActive] = useState(false)
    const [subModalActive, setSubModalActive] = useState(false)
    const [permissionModalActive, setPermissionModalActive] = useState(false)
    const [infoModalActive, setInfoModalActive] = useState(false)
    const [chosenEdition, setChosenEdition] = useState('')
    const [loading, setLoading] = useState(true)
    const themeContext = useContext(ThemeContext);
    const {trackPageVisit} = useTracking()

    useEffect(() => {
        if(location.state && location.state.mode){
            switch(location.state.mode){
                case 'unavailable':
                    setInfoModalActive(true)
                    break;
                case 'noSub':
                    setSubModalActive(true)
                    break;
                case 'noPermission':
                    setPermissionModalActive(true)
                    break;
                case 'login':
                    setLoginModalActive(true)
                    break;
                default:
                    setInfoModalActive(true)
                    break;
            }
        }
    }, [])

    useEffect(() => { 
        setLoading(true)
        setEdition({})
        axios.get(`${themeContext.backend}${themeContext.appName}product/${match.params.id}`)
        .then((response) => {
            setEdition(response.data)
            // Track page view
            trackPageVisit(`Details - ${response.data.title}`)
            
        })
        .finally(() => {
            setLoading(false)
            
        })
    }, [])

    //Close login modal after successful login
    useEffect(() => {
        if(user.loggedIn){
            setLoginModalActive(false)
            if(chosenEdition){
                handleReadButton()
                //history.push(chosenEdition)
            }
            
        }
    }, [user])

    const handleReadButton = async () => {
        if(edition.permissions.length > 0){
            if(!user.loggedIn){
                setChosenEdition("/reader/" + edition.id + location.search)
                setLoginModalActive(true)
            } else {
                if(!checkPermission()){
                    setPermissionModalActive(true)
                    return
                } 
            } 
        }
        const isFree = getEditionSubModel(edition)
        const type = edition.productType === 'COURSE' ? '/course/' : '/reader/'
        if(isFree === 'unavailable'){
            setInfoModalActive(true)
        }
        if(isFree === 'free'){
            history.push(type + edition.id + location.search)
        } 
        if(isFree === 'paid') {
            
            if(!user.loggedIn){
                setChosenEdition(type + edition.id + location.search)
                setLoginModalActive(true)
            } else {
                const check = await checkSubscription()
                if(check === 'true'){
                    history.push(type + edition.id + location.search)
                } else {
                    setSubModalActive(true)
                }
            }
        }
        
    }

    const checkSubscription = async () => {
        let subscriptionIDs = ""
        edition.subscriptions.map((sub, index) => {
            if(index > 0){
                subscriptionIDs += ", "
            }
            subscriptionIDs += sub.id
        })

        return axios.post(`${themeContext.backend}${themeContext.appName}validateaccess/product/${edition.id}`, null, {params:{
            username: user.username,
            password: user.password,
            subscriptions: subscriptionIDs
          }})
          .then((response) => {
            const check: any = convert.xml2js(response.data, {
              compact: true,
              alwaysArray: true
              })
              const allowed = check.subscriptions[0].catalogs[0].catalog[0].access[0]._text[0]
              return allowed
            
          })
          .catch(error => {
            console.log('error', error)
          })
    }

    const checkPermission = () => {
        const match = edition.permissions.filter(element => user.permissions.includes(element.title))
        return match.length > 0
    } 

    const translateLocation = () => {
        if(location.state && location.state.from){
            if(location.state.from.includes("/category/")){

                var categoryFrom = location.state.from.substr(10)
                if(location.state.from.includes("/all")){
                    const reduce = location.state.from.slice(0, -4)
                    categoryFrom = reduce.substr(10)
                }
                if(categoryFrom === '0'){
                    return t('start')
                }
                
                const current = categories.find(category => category.id.toString() === categoryFrom)
                if(current){
                    return current.name
                }
                return ''
            } else {
                switch(location.state.from){
                    case '/':
                        return t('start')
                    case '/favorites':
                        return t('favorites')
                    case('/search'):
                        return t('search')
                    default:
                        return ''
                }
            }
          
        }
        
    }

    const handleFavoriteButton = () => {
        if(isFavorite){
            deleteFavorite(edition.id)
        } else {
            addFavorite(edition.id, edition.path, edition.cover, edition.title)
        }
    }

    const handleLogin = (username, password) => {
        login(themeContext.backend, themeContext.appName, username, password)
    }

    const handleClose = () => {
        setLoginModalActive(false)
        setSubModalActive(false)
        setInfoModalActive(false)
    }

    return (
        <>
        {
            !loading && 
            <ContentBody>
            {edition && edition.title ?
                <>
                {
                     translateLocation() !== '' &&
                    <Breadcrumbs onClick={history.goBack} label={translateLocation()}/> 
                }
                    
                    <Wrapper>
                        <ImagePreview cover={edition.cover} path={themeContext.backend + edition.path} previews={edition.previews} />
                        <DetailWrapper>
                            <MobileWrapper>
                                <ImagePreview mobile cover={edition.cover} path={themeContext.backend + edition.path} previews={edition.previews} />
                                <MobileTitleWrapper>
                                    <Title>{edition.title}</Title>
                                    {
                                        edition.subtitle &&
                                        <SubTitle>{edition.subtitle}</SubTitle>
                                    }
                                    
                                    <ButtonWrapper>
                                        <Button primary title={edition.productType === 'COURSE' ? t('start_now') : t('read_now')} onClick={handleReadButton}/>
                                        <Button  active={isFavorite} icon={IconFavorites} onClick={() => handleFavoriteButton()}/>
                                    </ButtonWrapper>
                                </MobileTitleWrapper>
                                
                            </MobileWrapper>
                            
                            <DescriptionHead>
                                {t('content')} 
                            </DescriptionHead>
                            <ExpandableText text={ edition.description}/>
{/*                             {edition.metadata && 
                                <MetaData>
                                {
                                    edition.metadata.publicationDate && 
                                    <MetaDataItem label={t('date')} data={moment(edition.metadata.publicationDate, 'YYYYMMDD').format('DD. MMMM YYYY')}/>
                                    }
                                {
                                    edition.metadata.pageCount && edition.metadata.pageCount > 0 &&
                                    <MetaDataItem label={t('number_of_pages')} data={edition.metadata.pageCount}/>
                                }
                                
                                {
                                    edition.metadata.editionNumber !== 'null / null' &&
                                    <MetaDataItem label={t('edition')} data={edition.metadata.editionNumber}/>
                                }
                                <MetaDataItem label="ISBN" data={edition.metadata.isbn}/>
                                <MetaDataItem label="ISSN" data={edition.metadata.issn}/>
                                <MetaDataItem label="EAN" data={edition.metadata.ean}/>
                                <MetaDataItem label={t('ordernumber')} data={edition.metadata.orderingNumber}/>
                                <MetaDataItem label={t('copyright')} data={edition.metadata.copyright}/>
                                <MetaDataItem label={t('publisher')} data={edition.metadata.publisher}/>
                                <MetaDataItem label={t('degree')} data={edition.metadata.degree}/>
                                <MetaDataItem label={t('effort')} data={edition.metadata.effort}/>

                                
                            </MetaData>
                            } */}

                            
                            {edition.metadata ? //TODO: wenn alle Kioske Metadaten Objekt haben, unteres if durch oberes ersetzen
                                <MetaData>
                                {
                                    edition.metadata.publicationDate && 
                                    <MetaDataItem label={t('date')} data={moment(edition.metadata.publicationDate, 'YYYYMMDD').format('DD. MMMM YYYY')}/>
                                    }
                                {
                                    edition.metadata.pageCount && edition.metadata.pageCount > 0 &&
                                    <MetaDataItem label={t('number_of_pages')} data={edition.metadata.pageCount}/>
                                }
                                
                                {
                                    edition.metadata.editionNumber !== 'null / null' &&
                                    <MetaDataItem label={t('edition')} data={edition.metadata.editionNumber}/>
                                }
                                <MetaDataItem label="ISBN" data={edition.metadata.isbn}/>
                                <MetaDataItem label="ISSN" data={edition.metadata.issn}/>
                                <MetaDataItem label="EAN" data={edition.metadata.ean}/>
                                <MetaDataItem label={t('ordernumber')} data={edition.metadata.orderingNumber}/>
                                <MetaDataItem label={t('copyright')} data={edition.metadata.copyright}/>
                                <MetaDataItem label={t('publisher')} data={edition.metadata.publisher}/>
                                <MetaDataItem label={t('degree')} data={edition.metadata.degree}/>
                                <MetaDataItem label={t('effort')} data={edition.metadata.effort}/>

                                
                            </MetaData>
                            :
                            <MetaData>
                                <MetaDataItem label={t('date')} data={moment(edition.validFrom, 'YYYYMMDD').format('DD. MMMM YYYY')}/>
                                {
                                    edition.pageCount && edition.pageCount > 0 &&
                                    <MetaDataItem label={t('number_of_pages')} data={edition.pageCount}/>
                                }
                                
                                {
                                    edition.editionNumber !== 'null / null' &&
                                    <MetaDataItem label={t('edition')} data={edition.editionNumber}/>
                                }
                                <MetaDataItem label="ISBN" data={edition.isbn}/>
                                <MetaDataItem label="ISSN" data={edition.issn}/>
                                <MetaDataItem label="EAN" data={edition.ean}/>
                                <MetaDataItem label={t('ordernumber')} data={edition.orderingNumber}/>
                                <MetaDataItem label={t('copyright')} data={edition.copyright}/>
                                <MetaDataItem label={t('publisher')} data={edition.publisher}/>
                            
                            </MetaData>
                            }

                        </DetailWrapper>
                    </Wrapper>
                </>
                :
                <EmptyContent title={t('not_found_title')} description={t('not_found_description')} />
            }
            <LoginModal active={loginModalActive} user={user} subscriptions={subscriptions} close={() => handleClose()} login={handleLogin}/>
            <InfoModal title={t("edition_unavailable_title")} description={t("edition_unavailable_description")} link={{text: t("edition_unavailable_link"), url: t("edition_unavailable_link_target")}} active={infoModalActive} close={() => setInfoModalActive(false)}/>
            <InfoModal title={t("no_permission_title")} description={t("no_permission_description")}  active={permissionModalActive} close={() => setPermissionModalActive(false)}/>
            <InfoModal title={t("no_sub_title")} description={t("no_sub_description")} link={{text: t("no_sub_link"), url: t("no_sub_link_target")}} active={subModalActive} close={() => setSubModalActive(false)}>
                {
                    login.links &&
                    
                        <LinkWrapper>
                            {
                                themeContext.login.links.filter(link => subscriptions.some(sub => sub.id === link.id)).map((link, index) => (
                                    <Link key={index} target="_blank" href={link.url}>{link.text}</Link>
                                ))
                            }
                        </LinkWrapper>
                    
                }

            </InfoModal>
        </ContentBody>
        }
        </>
        
    )
}

const mapDispatchToProps = (dispatch) => ({
    addFavorite: (id, path, cover, title) => dispatch(addFavorite(id, path, cover, title)),
    deleteFavorite: (fav) => dispatch(deleteFavorite(fav)),
    loadEditions: () => dispatch(loadEditionsFromXML()),
    login: (backend, appName, username, password) => dispatch(login(backend, appName, username, password)),
  })

const mapStateToProps = (state, props) => {
    return {
      edition: state.editions.content.find(edition => edition.id === props.match.params.id),
      categories: state.categories,
      isFavorite: state.favorites.some(fav => fav.id === props.match.params.id),
      user: state.user,
      subscriptions: state.subscriptions
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EditionDetail)

const Wrapper = styled.div`
    display: flex;
`

const DetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    width: 100%;
    @media ${device.tablet}{
        margin-left: 10px;
    }
    @media ${device.mobile}{
        margin: 0;
    }

`

const MobileWrapper = styled.div`
    display: flex;
    margin-bottom: 48px;
    @media ${device.mobile}{
        margin-bottom: 20px;
    }
`

const MobileTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;

`

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 0 -6px;
    margin-top: 12px;

`

const Title = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
    margin-bottom: 12px;
    @media ${device.mobile}{
        font-size: 16px;
    }
    
`

const SubTitle = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.color.grey_500};
    margin-bottom: 12px;
`

const DescriptionHead = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
    margin-bottom: -14px;
`



const MetaData = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const LinkWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
`

const Link = styled.a`
    color: ${props => props.theme.color.primary};
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
`
