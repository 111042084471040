import { useEffect } from 'react';
import {connect} from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import Card from '../components/Card'
import ContentBody from "../components/ContentBody"
import EmptyContent from '../components/EmptyContent';
import ContentHeadline from '../components/ContentHeadline'
import {device} from "../media"
import { useTracking } from '../hooks/useTracking';

const Favorites = ({editions, favorites}) => {
    const {t} = useTranslation()

    const {trackPageVisit} = useTracking()

    // Track page view
    useEffect(() => {
        trackPageVisit({documentTitle: `Favoriten`})
    }, [])

    return (
        <ContentBody>
            {
                favorites.length > 0 ?
                <>
                <ContentHeadline text={ t('favorites')}/>
                 <Content>
                     
                     
                 {
                     favorites.map((item,index) => (
                         <Card key={index} edition={item}/>
                     ))
                     
                 }
                </Content>
                </>
                :
                <EmptyContent title={t('no_favorites_title')} description={t('no_favorites_description')} />
            }
           
        </ContentBody>
    )
}

const mapStateToProps = (state, props) => {
    return {
      editions: state.editions.content.filter(edition => state.favorites.some(favorite => edition.id === favorite.editionId)),
      favorites: state.favorites,
    }
}

export default connect(mapStateToProps)(Favorites)

const Content = styled.div`
  display: flex;
  margin: 0 -12px;
  margin-top: 4px;
  flex-wrap: wrap;
  margin-bottom: 12px;
  @media ${device.mobile}{
    margin: 0;
    }
`


