import {useContext} from 'react'
import styled from 'styled-components'
import SVG from "react-inlinesvg";
import {Link, useLocation} from 'react-router-dom'
import IconCategory from "../img/icons/icon_category.svg"
import moment from 'moment-with-locales-es6'
import { ThemeContext } from 'styled-components';

type Props = {
    edition: any,
    showProductType?: any,
    categories?: any
}

const CardList = ({edition, showProductType, categories}: Props) => {
    const location = useLocation()
    const themeContext = useContext(ThemeContext);
    const listCategories = () => {
            var list = ''
            const section = themeContext.sections.find((current) => current.type === edition.productType)
            edition.categories.forEach((category, index) => {
                if(section.label !== category.name){
                    if(list !== ''){
                        list += ", "
                    }
                    list += category.name
                }

            });
    
            return list

    }

    const renderProductType = () => {
        const section = themeContext.sections.find((current) => current.type === edition.productType)

        return (
            <CategoryWrapper>
                <HorizontalAlign>
                    <Icon src={section.icon}/>
                    <Category>{section.label_single}</Category>
                </HorizontalAlign>
            </CategoryWrapper>
        )

    }

    return (
        <StyledLink to={{pathname: `/edition/${edition.id}`, state: { from: location.pathname }}}>
            <Cover src={edition.cover.startsWith("https://") ? edition.cover : themeContext.backend + edition.path + "/" + edition.cover}/>
            <Details>
                <Title>{edition.title}</Title>
                {
                    edition.subtitle &&
                    <Subtitle>{edition.subtitle}</Subtitle>
                }
                
                <Date>{moment(edition.validFrom, 'YYYYMMDD').format('DD. MMMM YYYY')}</Date>
                {
                    showProductType && themeContext.sections.length > 1 ?
                    renderProductType()
                    : 
                    <CategoryWrapper>
                        <HorizontalAlign>
                            <Icon src={IconCategory}/>
                            <Category>{listCategories()}</Category>
                        </HorizontalAlign>
                    </CategoryWrapper>
                }

            </Details>
        </StyledLink>
    )
}

export default CardList

const StyledLink = styled(Link)`
    display: flex;
    cursor: pointer;
    margin-top: 28px;
    &:last-of-type div{
        border-bottom: none;
    }
`

const Cover = styled.img`
    width: 96px;   
    height: 100%;   
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    /* Shadow / MD */

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 28px;

`

const Details = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    padding-bottom: 28px;
    border-bottom: 1px solid ${props => props.theme.color.grey_100};
    min-height: 90px;

`

const Title = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
    margin-bottom: 12px;
`

const Subtitle = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
    margin-bottom: 8px;
`

const Date = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
`

const CategoryWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
`

const HorizontalAlign = styled.span`
    display: flex;
    align-items: center;
`

const Icon = styled(SVG)`
    margin-right: 8px;
`

const Category = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
`
