import {useState, useEffect, useContext} from 'react'
import {connect} from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios'
import ContentBody from "../components/ContentBody"
import EditionListFull from '../components/EditionListFull'
import EditionListShort from '../components/EditionListShort'
import Breadcrumbs from '../components/Breadcrumbs'
import { ThemeContext } from 'styled-components';
import ContentHeadline from '../components/ContentHeadline';
import { useTracking } from '../hooks/useTracking';
import {updateSection} from "../redux/actions/currentActions"

const Category = ({match, allCategories, currentCategory, subCategories, updateSection}) => {
    const [category, setCategory] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const location = useLocation()
    const themeContext = useContext(ThemeContext);
    const {t} = useTranslation()
    const history = useHistory()

    const {trackPageVisit} = useTracking()

    


    useEffect(() => {
        setCategory([])
        axios.get(`${themeContext.backend}${themeContext.appName}category/${match.params.id}${location.pathname.includes('/all') ? '?showAll=true' : ''}`)
        .then((response) => {
            setCategory(response.data)
            setCurrentPage(1)

            // Track page view
            trackPageVisit({documentTitle: `Kategorie - ${currentCategory.name}`})
        })
    }, [match.params.id, location.pathname])

    useEffect(() => {
      if(themeContext.sections.some(e => e.id.toString() === match.params.id)){
        updateSection(parseInt(match.params.id))
      }
    }, [match.params.id])

    const handlePages = (page) => {
        setCategory([])
        
        axios.get(`${themeContext.backend}${themeContext.appName}category/${match.params.id}?page=${page-1}${location.pathname.includes('/all') ? '&showAll=true' : ''}`)
        .then((response) => {
            setCategory(response.data)
            setCurrentPage(response.data.pageable.pageNumber + 1)
        })
    }

    const getBreadcrumbLabel = () => {
        const parent = allCategories.find(cat => cat.id === currentCategory.parent)
        return parent.name
    } 

    const getAllString = (current) => {
        let thisType = ""
        const fullCategory = allCategories.find(cat => cat.id === current.id)

        if(fullCategory){
            thisType = fullCategory.productType
        } else {
            
            if(current.content.length > 0){
                thisType = current.content[0].productType
            } else {
                return t("newest_editions")
            }
            
        }

        switch(thisType){
            case "EDITION":
                return t("newest_editions")
            case "COURSE":
                return t("newest_courses")
            default:
                return t("newest_editions")
        }
    }

    return (
        <ContentBody>
            
            
            {
                currentCategory && currentCategory.parent && (themeContext.sections.length === 1 ? !themeContext.sections.some(section => section.id === currentCategory.parent) : true) && !location.pathname.includes('/all') &&
                    <Breadcrumbs onClick={() => history.push(`/category/${currentCategory.parent}`)} label={getBreadcrumbLabel()}/> 
                    
            }
            {
                currentCategory && currentCategory.id && location.pathname.includes('/all') &&  
                    <Breadcrumbs onClick={() => history.push(`/category/${currentCategory.id}`)} label={currentCategory.name}/> 
            }
            {
                currentCategory && !currentCategory.id && location.pathname.includes('/all') &&  
                    <Breadcrumbs onClick={() => history.push(`/`)} label={t('start')}/> 
            }
            {
                //@ts-ignore
                category.categories &&
                <ContentHeadline text={currentCategory.name}/>
            }
           
            
            {
                //@ts-ignore
                category.categories && category.categories.map((subCategory, index) => (
                    <EditionListShort  key={index} route={match.params.id} category={subCategory} name={index === 0 ? getAllString(subCategory) : subCategory.name} updateSection={updateSection}/>
                ))
                
            }
            {
                //@ts-ignore
                category.content &&
                //@ts-ignore
                <EditionListFull editions={category.content} category={location.pathname.includes('/all') ? getAllString(category) : currentCategory.name } pages={category.totalPages} currentPage={currentPage} setCurrentPage={handlePages}/>
            }
            
            
        </ContentBody>
    )
}

const mapDispatchToProps = (dispatch) => ({
    updateSection: (section) => dispatch(updateSection(section)),
  })

const mapStateToProps = (state, props) => {
    return {
        allCategories: state.categories,
        currentCategory: state.categories.find(category => category.id.toString() === props.match.params.id) || {name: ''},
        subCategories: state.categories.filter(category => category.parent === props.match.params.id),
        editions: state.editions.content,
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Category)