import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components'

import {connect} from 'react-redux'

import { ThemeContext } from 'styled-components';
import {device} from "../media"
import IconChevronRight from '../img/icons/icon_chevron_right_large.svg'
import IconChevronLeft from '../img/icons/icon_chevron_left_large.svg'
import IconDoubleChevronRight from '../img/icons/icon_doublechevron_right.svg'
import IconDoubleChevronLeft from '../img/icons/icon_doublechevron_left.svg'
import IconFullscreen from '../img/icons/icon_fullscreen.svg'
import IconSearch from '../img/icons/icon_search.svg'
import IconMinus from '../img/icons/icon_minus.svg'
import IconPlus from '../img/icons/icon_plus.svg'
import IconList from '../img/icons/icon_list.svg'
import IconCollection from '../img/icons/icon_collection.svg'
import ToolbarButton from '../reader/ToolbarButton'
import PageNumbers from './PageNumbers';
import PagePicker from './PagePicker';
import ReaderSearch from './ReaderSearch';
import ContentsTable from '../reader/ContentsTable'
import CustomSlider from '../reader/CustomSlider'

const ToolbarBottom = ({currentEdition, fullscreenHandle}) => {
    const [sliderValue, setSliderValue] = useState(1)
    const themeContext = useContext(ThemeContext);
    const [pageNumbers, setPageNumbers] = useState([])
    const [activeButtons, setActiveButtons] = useState({search: "false", sitemap: "false"})
    const [doublePages, setDoublePages] = useState(false)
    const [texts, setTexts] = useState({goToTitle: "NAVIGIERE HIERHIN", noSearchResult: "Keine Ergebnisse"})
    const [pages, setPages] = useState([])
    const [sitemap, setSitemap] = useState({content: []})
    const [sitemapActive, setSitemapActive] = useState(false)
    const [readersearchActive, setReadersearchActive] = useState(false)
    const [pagepickerActive, setPagepickerActive] = useState(false)
    const [maxZoom, setMaxZoom] = useState(100)
    const [readiumState, setReadiumState] = useState(false)

    const handleSliderChange = (value, zoom) => {
        value = Math.min(Math.max(value, 1),maxZoom)
        setSliderValue(value)
        if(zoom){
            sendPostMessage('zoom', value)
        }
        
        
    }

    useEffect(() => {
        function receiveMessage (event) {
            switch(event.data.type){
                case 'piosk':
                    setDoublePages(event.data.doublePages)
                    setPages(event.data.pages)
                    setSitemap(event.data.sitemap)
                    setActiveButtons(event.data.buttons)
                    setTexts(event.data.texts)
                    setMaxZoom(event.data.maxScale)
                    break
                case "zoomScale":
                    handleSliderChange(event.data.scale, false)
                    break
                case "pageNumbers":
                    const numbers = [event.data.numbers.left]
                    if(event.data.numbers.right){
                        numbers.push(event.data.numbers.right)
                    }
                    setPageNumbers(numbers)
                    break
                case 'readium':
                    setReadiumState(event.data.active)
                    break
            }
        }

        window.addEventListener("message", receiveMessage)
        return () => {
            window.removeEventListener("message", receiveMessage)
        }
    }, [])
    
    const sendPostMessage = (type: string, number: number) => {
        window.readerFrame.postMessage({type, number}, "*")
    }

    return (
        <>
        {
            pageNumbers[0] &&
            <Wrapper>
            <Content>
                {
                    !readiumState && 
                    <>
                        <IconsLeft>
                            {
                                activeButtons.sitemap === "true" && sitemap.content.length > 0 &&
                                <ToolbarButton src={IconList} popup popupActive={sitemapActive} setPopupActive={setSitemapActive}>
                                    <ContentsTable sitemap={sitemap} setPopupActive={setSitemapActive}/>
                                </ToolbarButton>
                            }
                            
                            {
                                activeButtons.search === "true" && 
                                <ToolbarButton src={IconSearch} popup popupActive={readersearchActive} setPopupActive={setReadersearchActive}>
                                    <ReaderSearch noResultText={texts.noSearchResult} currentEdition={currentEdition} setPopupActive={setReadersearchActive}/>
                                </ToolbarButton>
                            }

                            <ToolbarButton  src={IconCollection} popup popupActive={pagepickerActive} setPopupActive={setPagepickerActive}>
                                <PagePicker onClick={(id) => sendPostMessage('goToPage', id - 1)} setPopupActive={setPagepickerActive} currentEdition={currentEdition} pages={pages} doublePages={doublePages}/>
                            </ToolbarButton>
                        </IconsLeft>
                        <IconsCenter>
                        <ToolbarButton mobilehidden src={IconDoubleChevronLeft} onClick={() => sendPostMessage('pageFirst', 0)}/>
                        <ToolbarButton src={IconChevronLeft} onClick={() => sendPostMessage('pageBack', 0)}/>
                        <PageNumbers title={texts.goToTitle} numbers={pageNumbers}/>
                        <ToolbarButton src={IconChevronRight} onClick={() => sendPostMessage('pageNext', 0)}/>
                        <ToolbarButton mobilehidden src={IconDoubleChevronRight} onClick={() => sendPostMessage('pageLast', 0)}/>
                        </IconsCenter>
                        <IconsRight>
                            <ToolbarButton disabled={sliderValue === 1} src={IconMinus} onClick={() => handleSliderChange(sliderValue - 1, true)}/>
                            <SliderWrapper>
                                <CustomSlider themeContext={themeContext} min={1} max={maxZoom} step={0.1} value={sliderValue} onChange={e => handleSliderChange(e, true)}/>
                            </SliderWrapper>
                            <ToolbarButton disabled={sliderValue === maxZoom} src={IconPlus} onClick={() => handleSliderChange(sliderValue + 1, true)}/>
                            <FullscreenButton>
                                <ToolbarButton src={IconFullscreen} onClick={fullscreenHandle.active ? fullscreenHandle.exit : fullscreenHandle.enter}/>
                            </FullscreenButton>
                            
                        </IconsRight>
                    </>
                }
            </Content>
            
        </Wrapper>
        }
        </>

    )
}

const mapStateToProps = (state, props) => {
    return {
      currentEdition: state.editions.current.url,
      currentEditionTitle: state.editions.current.title
    }
}

export default connect(mapStateToProps)(ToolbarBottom)

const Wrapper = styled.div`
    height: 76px;  
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-top: 1px solid ${props => props.theme.color.grey_200};
    @media ${device.mobile}{
        height: 56px;
        position: fixed;
        bottom: 0;
        left: 0;
    }
`

const Content = styled.span`
    width: 960px;
    @media ${device.tablet}{
        width: 820px;
    }
    display: flex;
    margin: 0 12px;
    justify-content: space-between;
`

const IconsLeft = styled.div`
    display: flex;
    flex: 1;
`

const IconsCenter = styled.div`
    display: flex;
`

const IconsRight = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    @media ${device.mobile}{
        display: none;
    }
`
const FullscreenButton = styled.span`
    display: block;
    @media ${device.tablet}{
        display: none;
    }
`

const SliderWrapper = styled.div`
    width: 150px;
`

