import {useState, useEffect} from 'react'
import styled from 'styled-components'
import SVG from "react-inlinesvg";

import IconClose from '../img/icons/icon_close.svg'
import IconRight from '../img/icons/icon_arrow_right.svg'
import IconLeft from '../img/icons/icon_arrow_left.svg'
import {device} from "../media"

interface ModalProps {
    active: boolean
}

interface Props {
    cover: any,
    previews: any,
    path: string,
    mobile?: boolean
}

const ImagePreview = ({path, cover, previews, mobile}: Props) => {
    const [modal, setModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [bigImage, setBigImage] = useState(cover)

    useEffect(() => {
        if(currentIndex === 0){
            setBigImage(cover)
        } else {
            setBigImage(previews[currentIndex - 1].image)
        }
    }, [currentIndex, cover, previews])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleESC(event) {
          if(event.key === "Escape"){
              setModal(false)
          }
        }
    
        // Bind the event listener
        document.addEventListener("keydown", handleESC);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("keydown", handleESC);
        };
    }, []);

    const handleImageChange = (direction) => {
        switch(direction){
            case 'left':
                if(currentIndex === 0){
                    setCurrentIndex(previews.length)
                } else {
                    setCurrentIndex(currentIndex - 1)
                }
                break;
            case 'right':
                if(currentIndex === previews.length){
                    setCurrentIndex(0)
                } else {
                    setCurrentIndex(currentIndex + 1)
                }
                break;
            default: 
                break;

            
        }
    }

    const handleModal = (index) => {
        setCurrentIndex(index)
        setModal(true)
    }

    return (
        <Wrapper>
            {
                !!mobile ?  
                    <MobileCover onClick={() => handleModal(0)} src={cover.startsWith("https://") ? cover : path + "/" + cover}/>
                :
                <>
                    <Cover src={cover.startsWith("https://") ? cover : path + "/" + cover} onClick={() => handleModal(0)}/>
                
                    <SmallImageWrapper>
                        {
                            previews && previews.map((img, index) => (
                                <Preview key={img.image} src={img.image.startsWith("https://") ? img.image : path + "/" + img.image} onClick={() => handleModal(index + 1)}/>
                            ))
                        }
                        
                    </SmallImageWrapper>
                </>
            }

            {
                modal &&
                <Modal>
                    <Close src={IconClose} onClick={() => setModal(false)}/>
                    <ModalContent>
                        <BigImage src={bigImage.startsWith("https://") ? bigImage : path + "/" + bigImage}/>
                        {
                            previews && previews.length > 0 &&
                            <BottomRow>
                            <ImageRow>
                                <SmallImage active={bigImage === cover} src={cover.startsWith("https://") ? cover : path + "/" + cover} onClick={() => setCurrentIndex(0)}/>
                                {
                                    previews && previews.map((img, index) => (
                                        <SmallImage active={bigImage === img.image} key={img.image} src={img.image.startsWith("https://") ? img.image : path + "/" + img.image} onClick={() => setCurrentIndex(index + 1)}/>
                                    ))    
                                }
                                
                            </ImageRow>
                            <IconWrapper>
                                <ChangeIcon src={IconLeft} onClick={() => handleImageChange('left')}/>
                                <ChangeIcon src={IconRight} onClick={() => handleImageChange('right')}/>
                            </IconWrapper>
                        </BottomRow>
                        }
                        
                    </ModalContent>
                </Modal>
            }

        </Wrapper>
    )
}

export default ImagePreview

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Cover = styled.img`
    width: 200px;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    /* Shadow / MD */

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    @media ${device.mobile}{
        display: none;
    }
`

const MobileCover = styled.img`
    width: 100px;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    /* Shadow / MD */

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    display: none;
    margin-right: 16px;
    @media ${device.mobile}{
        display: inline;
    }

`

const SmallImageWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    @media ${device.mobile}{
        display: none;
    }
`

const Preview = styled.img`
    width: 44px;
    height: 100%;
    margin-right: 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    /* Shadow / MD */

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    cursor: pointer;
`

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(15, 23, 42, 0.96);
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;

`

const Close = styled(SVG)`
    position: absolute;
    top: 0;
    right: 0;
    margin: 40px;
    cursor: pointer;
    @media ${device.mobile}{
        margin: 25px;

    }
`

const ModalContent = styled.div`
    
`

const BigImage = styled.img`
    width: 400px;
    border: 8px solid rgba(255, 255, 255, 0.16);
    border-radius: 12px;
    opacity: 1;
    z-index: 10;
    @media ${device.mobile}{
        width: 280px;

    }
`

const SmallImage = styled.img<ModalProps>`
    width: 44px;
    border-radius: 2px;
    margin: 0 4px;
    cursor: pointer;
    opacity: ${props => props.active ? 1 : 0.2};

`

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`

const ImageRow = styled.div`    
    margin: 0 -4px;

`

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.mobile}{
        display: none;

    }

`
const ChangeIcon = styled(SVG)`
    margin: 0 11px;
    cursor: pointer;
`