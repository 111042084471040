import {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import {connect} from 'react-redux'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import ContentBody from "../components/ContentBody"
import SearchBar from "../components/SearchBar"
import CardList from "../components/CardList"
import Pagination from '../components/Pagination'
import { ThemeContext } from 'styled-components';
import ContentHeadline from '../components/ContentHeadline'
import { useTracking } from '../hooks/useTracking';
import { useHistoryState } from '../hooks/useHistoryState';

const Search = ({categories}) => {
    const [searchValue, setSearchValue] = useHistoryState('searchValue','')
    const [result, setResult]: any = useState({})
    const themeContext = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useHistoryState('currentPage', 1)
    const {t} = useTranslation()
    
    const {trackPageVisit} = useTracking()
    const filterAreaOptions = [{value: "allAreas", label: "Alle Bereiche"}].concat(themeContext.sections.map((section) => {
        return {value: section.type, label: section.label}
    }))
    const [filterAreaValue, setFilterAreaValue] = useHistoryState("filterAreaValue", filterAreaOptions[0])

    // Track page view
    useEffect(() => {
        trackPageVisit({documentTitle: 'Suche'})
    }, [])

    useEffect(() => {
        
        if(searchValue !== ''){
            handleSearch(currentPage, "")
        }
    }, [filterAreaValue])

    const handleSearch = (page, e) => {
        try{
            e.preventDefault()
        } catch(error){

        }
        if(searchValue === ''){
            return
        }
        setResult({})
        axios.get(`${themeContext.backend}${themeContext.appName}products/search?searchTerm=${searchValue}&page=${page-1}${filterAreaValue.value !== filterAreaOptions[0].value ? "&productType="+filterAreaValue.value.toUpperCase() : ""}`)
        .then((response) => {
          setResult(response.data)
          setCurrentPage(response.data.pageable.pageNumber + 1)
        })
    }

    return (
        <ContentBody>
            <ContentHeadline text={t('search')}/>
            <SearchBar 
                value={searchValue} 
                setValue={setSearchValue} 
                filterAreaValue={filterAreaValue}
                setFilterAreaValue={setFilterAreaValue}
                filterAreaOptions={filterAreaOptions}
                onSearchButton={(e) => handleSearch(1, e)}/>
            {
                result.content &&
                <ResultText>
                    <Trans i18nKey='searchresult' values={{ number: result.totalElements }}>
                        Insgesamt <strong>42</strong> Suchergebnisse
                    </Trans>
                </ResultText>
            }

            {
                result.content && result.content.map((edition) => (
                    <CardList edition={edition} showProductType={filterAreaValue.value === filterAreaOptions[0].value} categories={categories}/>
                ))
            }
            {
                result.totalPages > 1 &&
                <Pagination pages={result.totalPages} currentPage={currentPage} setCurrentPage={handleSearch}/>
            }
            
        </ContentBody>
    )
}

const mapStateToProps = (state) => {
    return {
      categories: state.categories
    }
}

export default connect(mapStateToProps)(Search)

const ResultText = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500}
`