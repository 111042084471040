import {useState, useEffect, useContext} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import {connect} from 'react-redux'
import convert from 'xml-js'
import axios from 'axios'
import IFrameReader from '../reader/IFrameReader'
import { ThemeContext } from 'styled-components';
import {setCurrent} from '../redux/actions/editionActions'
import {getEditionSubModel} from '../helper/editionHelper'
import { useTracking } from '../hooks/useTracking';

declare global {
    interface Window {
        readerFrame: any;
    }
}

/**
 * The iFrame that hosts the reader.
 * 
 * Is needed for kiosk-reader communication.
 */
window.readerFrame = window.frames[0];

const Reader = ({match, user, setCurrentEdition}) => {
    const [edition, setEdition]: any = useState()
    const [editionAvailable, setEditionAvailable] = useState(false)
    const themeContext = useContext(ThemeContext);
    const history = useHistory()
    const location = useLocation()
    const {customCommand} = useTracking()

    useEffect(() => {
        setEdition({})
        axios.get(`${themeContext.backend}${themeContext.appName}edition/${match.params.id}`)
        .then((response) => {
            setEdition(response.data)
            setCurrentEdition({url: `${themeContext.backend}${response.data.path}`, title: response.data.title, type: response.data.productType, shareURL: response.data.sharing ? response.data.sharing.defaultHref : "", hasLesemodus: response.data.metadata ? response.data.metadata.types.epub : false})

        })
    }, [])

    useEffect(() => {
        function eventListener(event) {
            if (event?.data?.type === "piosk") {
                window.readerFrame = event.source ?? window.readerFrame ?? window.frames[0]
                window.removeEventListener("message", eventListener)
            }
        }
        window.addEventListener("message", eventListener)
        return () => window.removeEventListener("message", eventListener)
    })

    useEffect(() => {
        if(edition && edition.path){
            checkAvailability()
        }
        
    }, [edition])

    useEffect(() => {
        if(editionAvailable){
            // Track page view
            const dimensions = {}
            if(edition.metadata){
                if(edition.metadata.publisher){
                    dimensions["dimension1"] = edition.metadata.publisher
                }
                if(edition.metadata.isbn){
                    dimensions["dimension2"] = edition.metadata.isbn
                }
                if(edition.metadata.issn){
                    dimensions["dimension3"] = edition.metadata.issn
                }
                if(edition.metadata.ean){
                    dimensions["dimension4"] = edition.metadata.ean
                }
                if(edition.metadata.orderingNumber){
                    dimensions["dimension5"] = edition.metadata.orderingNumber
                }
            } else {
                if(edition.publisher){
                    dimensions["dimension1"] = edition.publisher
                }
                if(edition.isbn){
                    dimensions["dimension2"] = edition.isbn
                }
                if(edition.issn){
                    dimensions["dimension3"] = edition.issn
                }
                if(edition.ean){
                    dimensions["dimension4"] = edition.ean
                }
                if(edition.orderingNumber){
                    dimensions["dimension5"] = edition.orderingNumber
                }
            }

            customCommand({type: 'trackPageView', title: `Reader - ${edition.title}`, dimensions})
        }

    }, [editionAvailable])

    const checkPermission = () => {
        const match = edition.permissions.filter(element => user.permissions.includes(element.title))
        return match.length > 0
    } 

    const checkAvailability = async () =>{
        const isFree = getEditionSubModel(edition)
        if(edition.permissions.length > 0){
            if(!user.loggedIn){
                history.push({pathname: `/edition/${match.params.id}`, search: location.search,state: { mode: 'login' }})
            } else {
                if(!checkPermission()){
                    history.push({pathname: `/edition/${match.params.id}`, search: location.search, state: { mode: 'noPermission' }})
                    return
                } 
            } 
        }
        if(isFree === 'unavailable'){
            history.push({pathname: `/detail/${match.params.id}${location.search}`, state: { mode: 'unavailable' }})
        }
        if(isFree === 'free'){
            setEditionAvailable(true)
        } 
        if(isFree === 'paid') {
            if(!user.loggedIn){
                history.push({pathname: `/detail/${match.params.id}`, search: location.search,state: { mode: 'login' }})
            } else {
                const check = await checkSubscription()
                if(check === 'true'){
                    setEditionAvailable(true)
                } else {
                    history.push({pathname: `/detail/${match.params.id}`, search: location.search, state: { mode: 'noSub' }})
                }
            }
        }
    }
       
    

    const checkSubscription = async () => {
        let subscriptionIDs = ""
        edition.subscriptions.map((sub, index) => {
            if(index > 0){
                subscriptionIDs += ", "
            }
            subscriptionIDs += sub.id
        })

        return axios.post(`${themeContext.backend}${themeContext.appName}validateaccess/product/${edition.id}`, null, {params:{
            username: user.username,
            password: user.password,
            subscriptions: subscriptionIDs
          }})
          .then((response) => {
            const check: any = convert.xml2js(response.data, {
              compact: true,
              alwaysArray: true
              })
              const allowed = check.subscriptions[0].catalogs[0].catalog[0].access[0]._text[0]
              return allowed
            
          })
          .catch(error => {
            console.log('error', error)
          })
    }

    return(
        <>
        {
            edition && edition.path && editionAvailable && 
                <IFrameReader src={`${themeContext.backend}${edition.path}`} query={location.search.substring(1)} username={user.loggedIn && edition.productType === 'COURSE' ? user.username : null} password={user.loggedIn && edition.productType === 'COURSE' ? user.password : null}/>            
        }
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setCurrentEdition: (edition) => dispatch(setCurrent(edition)),
  })

const mapStateToProps = (state, props) => {
    return {
      user: state.user,
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Reader)
