import {useState,useContext, useEffect, useRef} from 'react'
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import SVG from "react-inlinesvg";
import IconFilter from '../img/icons/icon_filter.svg'
import IconSearch from '../img/icons/icon_search.svg'
import IconSearchDelete from '../img/icons/icon_search_delete.svg'
import Select from 'react-select';

import styled from 'styled-components'
import Button from '../components/Button'
import Dropdown from './Dropdown';

const SearchBar = ({value, setValue, filterAreaOptions, filterAreaValue, setFilterAreaValue,  onSearchButton}) => {
    const {t} = useTranslation()
    const themeContext = useContext(ThemeContext);
    const [dropdownActive, setDropdownActive] = useState(false)
    const ref = useRef(null)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                    // @ts-ignore
                    setDropdownActive(false)

                
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);




    return (
        <Wrapper>
            <SearchWrapper>
                <SearchIcons>
                    <IconLeft src={IconSearch}/>
                    {
                        value &&
                        <IconRight onClick={() => setValue("")} src={IconSearchDelete}/>
                    }
                    
                </SearchIcons>
                <SearchForm onSubmit={onSearchButton}>
                <SearchInput placeholder={t('search_placeholder')} value={value} onChange={(e) => setValue(e.target.value)}/>
                </SearchForm>
                {
                    filterAreaOptions.length > 2 && 
                    <OutsideClickHelper ref={ref}>
                        <FilterButton  onClick={() => setDropdownActive(!dropdownActive)}>
                            <FilterIcon src={IconFilter}/>
                            {t('filter')}

                        </FilterButton>
                        <Dropdown active={dropdownActive}>
                                <DropdownWrapper>
                                    <DropdownTitle>{t('filter_title')}</DropdownTitle>
                                <Select
                                    styles={selectStyles}
                                    value={filterAreaValue}
                                    onChange={setFilterAreaValue}
                                    options={filterAreaOptions}
                                    />
                                </DropdownWrapper>
                        </Dropdown>
                    </OutsideClickHelper>

                }


            </SearchWrapper>
            
            <Button primary inactive={value === ''} title={t('search')} onClick={onSearchButton}/>
        </Wrapper>
    )
}

export default SearchBar

const selectStyles = {
    indicatorSeparator: () => ({
        width: '0px',
    }),
    input: (provided, _) => ({
        ...provided,
        fontWeight: 400,
        fontSize: '14px',
        color: "#0F172A"
    }),
    control: (provided, _) => ({
        ...provided,
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        height: "38px",
        boxShadow: 'none',
        "&:hover": {
            border: "1px solid #CBD5E1"
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#F1F5F9' : state.isFocused ? "#F8FAFC" : 'FFF',
        color: 'black',
        cursor: state.isFocused ? 'pointer' : 'auto',
        fontSize: "14px",
        padding: "12px",
        "&:active": {
            backgroundColor: state.isSelected ? "#E2E8F0" : '#F1F5F9'
        }
    }),
    menu: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
    })
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 32px;

`

const SearchWrapper = styled.div`
    position: relative;
    border: 1px solid ${props => props.theme.color.grey_200};
    box-sizing: border-box;
    /* Shadow/XS */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    height: 38px;
    width: 100%;
    &:focus-within {
        border: 1px solid ${props => props.theme.color.primary};
    }
`

const SearchIcons = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    pointer-events: none;
`


const IconRight = styled(SVG)`
    margin-right: ${props => props.theme.sections.length > 1 ? "110px" : "20px"};
    cursor: pointer;
    pointer-events: all;
`
const IconLeft = styled(SVG)`
    margin-left: 12px;
`

const FilterIcon = styled(SVG)`
    margin-right: 8px;
`   

const SearchForm = styled.form`
    flex: 1;
    display: flex; 
    align-items: center;
    
`

const SearchInput = styled.input`
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    margin-left: 34px;
    padding-right: 44px;
    color: ${props => props.theme.color.grey_900};
    &:focus {
        outline: none;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${props => props.theme.color.grey_400};
    }
    :-ms-input-placeholder {
        color: ${props => props.theme.color.grey_400};
    }
`

const DropdownWrapper = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
`

const DropdownTitle = styled.span`
    margin-bottom: 6px;
    color: ${props => props.theme.color.grey_700};
    font-weight: 500;
    font-size: 14px;
`

const FilterButton = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-weight: 500;
    font-size: 14px;
    color: ${props => props.theme.color.grey_900};
    background: ${props => props.theme.color.grey_100};
    -moz-border-radius-topright: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-bottomright: 8px;
    -webkit-border-bottom-right-radius: 8px;
    height: 100%;
    &:hover {
        background: ${props => props.theme.color.grey_200};
    }
`

const OutsideClickHelper = styled.span``