import moment from 'moment-with-locales-es6'

export const updateFavorites = (favorites) => ({
  type: 'UPDATE_FAVORITES',
  favorites
})

export const addFavorite = (id, path, cover, title) => {
  return (dispatch, getState) => {
    const {favorites} = getState()
    const newFavorite = {
        id: id,
        path: path,
        cover: cover,
        title: title,
        dateSet: moment()
    }
    localStorage.setItem('favorites', JSON.stringify([...favorites, newFavorite]))
    dispatch(updateFavorites([...favorites, newFavorite]))
  }
}

export const deleteFavorite = (id) => {
  return (dispatch, getState) => {
    const {favorites} = getState()
    for(var i = 0; i < favorites.length; i++){
        if(favorites[i].id === id){
            favorites.splice(i, 1)
        }
    }
    localStorage.setItem('favorites', JSON.stringify([...favorites]))
    dispatch(updateFavorites([...favorites]))
  }
}

export const loadFavorites = () => {
  return (dispatch) => {
    const favorites = localStorage.getItem('favorites')
    if(favorites !== null){
      const parsed = JSON.parse(favorites)
      dispatch(updateFavorites(parsed))
    }

  }
}
