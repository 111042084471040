import { useContext} from 'react';
import { ThemeContext } from 'styled-components';

import styled from 'styled-components'

import {Link} from 'react-router-dom'
import {device} from "../media"
import LoginInfoButton from './LoginInfoButton';


const Header = () => {
    const themeContext = useContext(ThemeContext);


    return (
        <>
            <Wrapper>
                <Content>
                    {
                        themeContext.header.link ?
                        <ExternalLink target="blank" href={themeContext.header.link}>
                            <Logo src={themeContext.logoImage}/>
                        </ExternalLink>
                        :
                        <StyledLink to={"/"}>
                            <Logo src={themeContext.logoImage}/>
                        </StyledLink>

                    }
                    
                    <ButtonWrapper>
                            <LoginInfoButton/>                  
                    </ButtonWrapper>
                </Content>
            </Wrapper>
            
        </>
    )
}


export default Header

const Wrapper = styled.div`
    height: 76px;  
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    @media ${device.mobile}{
        height: 56px;
    }
`

const Content = styled.span`
    width: 960px;
    display: flex;
    justify-content: space-between;
    @media ${device.tablet}{
        width: 820px;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    @media ${device.tablet}{
        margin-right: 12px;
    }
    @media ${device.mobile}{
        margin-right: 12px;
    }
`

const StyledLink = styled(Link)`
`

const ExternalLink = styled.a`
`

const Logo = styled.img`
    height: 36px;

    @media ${device.tablet}{
        margin-left: 12px;
    }
    @media ${device.mobile}{
        margin-left: 12px;
        height: 32px;
    }
`
