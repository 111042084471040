import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '../components/Button'

interface Props{
    title: string,
    description: string
}

const EmptyContent = ({title, description}: Props) => {
    const {t} = useTranslation()
    const history = useHistory()

    return (
        <Wrapper>
            <Title>
                {title}
            </Title>
            <Description>
                {description}
            </Description>

            <Button primary onClick={() => history.push('/')} title={t('back_to_start')}/>
        </Wrapper>
    )
}

export default EmptyContent

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
`

const Title = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 125%;
    max-width: 446px;
    text-align: center;
    margin-bottom: 12px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const Description = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    max-width: 446px;
    text-align: center;
    margin-bottom: 24px;
    color: ${props => props.theme.color.grey_500}
`