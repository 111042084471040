import {useState, useEffect, useRef, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {connect} from 'react-redux'

import styled from 'styled-components'
import SVG from "react-inlinesvg";
import IconAvatar from "../img/icons/icon_avatar.svg"
import IconAvatarChecked from "../img/icons/icon_avatar_checked.svg"
import IconInfo from "../img/icons/icon_info.svg"
import IconTriangleDown from "../img/icons/icon_triangle_down.svg"
import LoginModal from "../components/LoginModal"
import Dropdown from "../components/Dropdown"
import {login, logout} from "../redux/actions/userActions"


const LoginInfoButton = ({subscriptions, user, login, logout}) => {
    const [dropdownActive, setDropdownActive] = useState(false)
    const [loginModalActive, setLoginModalActive] = useState(false)
    const ref = useRef(null)
    const {t} = useTranslation()
    const themeContext = useContext(ThemeContext);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                    // @ts-ignore
                    setDropdownActive(false)

                
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //Close login modal after successful login
    useEffect(() => {
        if(user.loggedIn){
            setLoginModalActive(false)
        }
    }, [user])

    const handleLogin = (username, password) => {
        login(themeContext.backend, themeContext.appName, username, password)
    }

    return(
        <>
        <Wrapper ref={ref} onClick={() => setDropdownActive(!dropdownActive)}>
                {
                    themeContext.login.type === "internal" ? 
                    (
                        user.loggedIn ?
                        <Icon src={IconAvatarChecked}/>
                        :
                        <Icon src={IconAvatar}/>

                    )
                    
                    :
                    <Icon src={IconInfo}/>
                }
                <Icon src={IconTriangleDown}/>

            <Dropdown active={dropdownActive}>
                {
                    themeContext.login.type === "internal" && !user.loggedIn &&
                    <LoginButton onClick={() => setLoginModalActive(true)}>
                        <Icon src={IconAvatar}/>
                        <LoginButtonText>{t('login')}</LoginButtonText>
                    </LoginButton>
                }
                {
                    themeContext.login.type === "internal" && user.loggedIn &&
                    <LoginButton onClick={() => logout()}>
                        <Icon src={IconAvatarChecked}/>
                        <LogoutTextWrapper>
                            <LoginButtonText>{user.username}</LoginButtonText>
                            <LogoutButtonText>{t('logout')}</LogoutButtonText>
                        </LogoutTextWrapper>
                        
                    </LoginButton>
                }
                {
                    themeContext.info.length > 0 && themeContext.info.map((item, index) => (
                        <DropdownLink key={index} target="blank" href={item.link}>{item.text}</DropdownLink>
                    ))
                }
            </Dropdown>
            

        </Wrapper>
        <LoginModal active={loginModalActive} close={() => setLoginModalActive(false)} login={handleLogin} subscriptions={subscriptions} user={user}/>
        </>
    )
}

const mapStateToProps = (state, props) => {
    return {
      subscriptions: state.subscriptions,
      user: state.user
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    login: (backend, appName, username, password) => dispatch(login(backend, appName, username, password)),
    logout: () => dispatch(logout())
  })

export default connect(mapStateToProps, mapDispatchToProps)(LoginInfoButton)

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
`

const Icon = styled(SVG)`
    &:last-of-type {
        margin-left: 9px;
    }

`



const DropdownLink = styled.a`
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.color.grey_700};
    &:hover {
        background-color: ${props => props.theme.color.grey_50};
    }
`

const LoginButton = styled.span`
    height: 66px;
    padding-left: 3px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid ${props => props.theme.color.grey_100};
    &:hover {
        background-color: ${props => props.theme.color.grey_50};
    }
`

const LoginButtonText = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_900};
    margin: 0 12px;
`

const LogoutTextWrapper = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const LogoutButtonText = styled.span`
    margin-top: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    margin-left: 12px;
    color: ${props => props.theme.color.grey_600};
`